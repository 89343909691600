import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const useStyles = makeStyles({
    blockCenter: {
        textAlign: 'center'
    },
    button: {
        color: '#01233f'
    },
  });

export default function Products(props) {
  const classes = useStyles();

  const handleClick = () => {
    props.handleClick();
  };

  return (
    <div className={classes.blockCenter}>
        <FontAwesomeIcon icon={props.icon} size="3x" color="#419a1c"/>
        <h2>{props.title}</h2>
        <p>{props.subDescription}</p>
        <Button variant="outlined" className={classes.button} onClick={handleClick}>{props.btnlabel}</Button>
    </div> 
  );
}