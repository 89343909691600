import React, {useState, useEffect, useContext} from 'react';

import { makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MainCarousel from './MainCarousel';
import 'typeface-roboto';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSchool, faUserGraduate,faUmbrellaBeach, faScroll, faExchangeAlt, faPiggyBank, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';

import Product from './mainProducts';

import { PageContext } from "../context/page-context";

library.add(faSchool, faUserGraduate, faUmbrellaBeach, faScroll, faExchangeAlt, faPiggyBank, faChalkboardTeacher);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 20
  },

  grid: {
    width: 1200,
    marginTop: 40
  },

  headline: {
    textAlign: 'center', // <-- the magic
    fontWeight: 'bold',
    fontSize: 22,
    marginTop: 40,
    marginBottom: 30,
    width: '100%',
    color: '#01233f'
  },
});

 export default function ProminentAppBar(props) {

  const classes = useStyles();
  const [State, Dispatch] = useContext(PageContext);
  const [copy, setCopy] = useState({});

  useEffect(() => {
    switch(State.language){
      case 'en':
        setCopy({
          siteheadline:"HongYang Education is a leading company in providing advice to students and business for British Education",
          siteReport:"School finder report",
          siteReportdesc:"The recommended first step in the search of opportunties to study in a UK educational provider. A bespoke report created by our consultant of 5 educational institutes will be produce that takes into consideration your requirements and our expert knowledge.",
          siteApplication:"School applications",
          siteApplicationdesc:"Britain has a long standing tradition of cultivating the social elite within the UK private school system and through our school application service we can provide your child the opportunity to stand side by side.",
          siteUniApplication:"University applications",
          siteUniApplicationdesc:"From bachelor degree all the way to PhD, We can guide you through the process of selecting the right University and course, prepare the application and guidance during your stay in the UK.",
          siteSummercourse:"Summer courses",
          siteSummercoursedesc:"Many private schools in the UK have setup Summer courses for their school and local children. Through our partnership with these schools we are able to provide you an opportunity to participate and discover what life is like in a UK private school.",
          siteExchange:"School Exchange",
          siteExchangedesc:"For students and teachers, the culture exchange between schools is a very interesting experience, especially as exchange with overseas schools is more like a short-term overseas trip.",
          siteInvestment:"Investing in UK Education",
          siteInvestmentdesc:"Investment in the UK private sector from Chinese companies is growing with schools like Bournemouth Collegiate School, St Bees, and Ipswich High School for Girls gone in the past year.  If you are interested in investing or acquiring a UK private school, contact us.",
          siteOnline:"Online Education",
          siteOnlinedesc:"Hosted by elite tutors who are doctoral’s within their chosen profession and in conjunction with Hongyang Education, we are pleased to be able to offer a number of online teaching course.",
          btnDiscovermore:"Discover more",
          btnBuynow:"Buy now"
        });
      break;
      default:
      case 'cn':
        setCopy({
          siteheadline:"弘扬教育是为英国教育做咨询以及为英国留学服务的专业化公司",
          siteReport:"择校报告",
          siteReportdesc:"为申请人量身定制留学申请院校选择报告。根据个人特点需求，我们经验丰富的顾问会推荐五所最佳的英国教育机构供您参考。",
          siteApplication:" 私校申请",
          siteApplicationdesc:"英国历史悠久，意识超前的私立中小学是培养社会精英的摇篮。我们将提供一站式的申请服务，确保您的孩子会踏入名校之门。",
          siteUniApplication:"高等院校申请",
          siteUniApplicationdesc:"从本科，硕士到博士，我们会在专业选择，材料准备 和 语言考试方面为您提供切实有效的指导。",
          siteSummercourse:"暑期夏令营",
          siteSummercoursedesc:"很多英国私立学校在暑期会开办以本校或本地学生为主的暑期学习班。通过我们的合作学校， 孩子们可以体验纯正的英国私校学习和生活。",
          siteExchange:"校际交流",
          siteExchangedesc:"对于学生和老师来说，学校与学校之间的文化交流是一种非常有趣的体验。我们公司与很多私立学校有良好的合作关系，如果您的学校有兴趣来英国做短期的校际交流，请与我们联系。",
          siteInvestment:"投资英国教育",
          siteInvestmentdesc:"近些年，国际教育迅速发展，中国公司在英国收购或投资私立学校成为英国教育发展的新动力。 如果您有兴趣联合办学或投资英国教育，请与我们联系。",
          siteOnline:"线上教育",
          siteOnlinedesc:"弘扬教育的博士精英导师团隆重推出线上教学课程，让您无伦身在何方，都可以和弘扬相约在云课堂",
          btnDiscovermore:"更多详情",
          btnBuynow:"Buy now"
        });
      break;
    }
  }, [State.language]);

  const gotoButtonClick = ($page) => {
    Dispatch({
      type: "setPage",
      payload: { page: $page, subPage: $page}
    });
  }

    return (
        <div className={classes.root}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
              <MainCarousel />     
          </Grid>
          <Grid item xs={12}>
              <div className={classes.headline}>
                {copy.siteheadline}
              </div>                 
          </Grid>
          <Grid item xs={12} sm={3}><Product icon={faScroll} title={copy.siteReport} subDescription={copy.siteReportdesc} handleClick={e => gotoButtonClick('personal')} btnlabel={copy.btnDiscovermore} /></Grid>
          <Grid item xs={12} sm={3}><Product icon={faSchool} title={copy.siteApplication} subDescription={copy.siteApplicationdesc} handleClick={e => gotoButtonClick('personal')} btnlabel={copy.btnDiscovermore} /></Grid>
          <Grid item xs={12} sm={3}><Product icon={faChalkboardTeacher} title={copy.siteOnline} subDescription={copy.siteOnlinedesc} handleClick={e => gotoButtonClick('teaching')} btnlabel={copy.btnDiscovermore} /></Grid>
          <Grid item xs={12} sm={3}><Product icon={faUmbrellaBeach} title={copy.siteSummercourse} subDescription={copy.siteSummercoursedesc} handleClick={e => gotoButtonClick('personal')} btnlabel={copy.btnDiscovermore} /></Grid>
          <Grid item xs={12} sm={3}><Product icon={faUserGraduate} title={copy.siteUniApplication} subDescription={copy.siteUniApplicationdesc} handleClick={e => gotoButtonClick('personal')} btnlabel={copy.btnDiscovermore} /></Grid>
          <Grid item xs={12} sm={3}><Product icon={faExchangeAlt} title={copy.siteExchange} subDescription={copy.siteExchangedesc} handleClick={e => gotoButtonClick('business')} btnlabel={copy.btnDiscovermore} /></Grid>
          <Grid item xs={12} sm={3}><Product icon={faPiggyBank} title={copy.siteInvestment} subDescription={copy.siteInvestmentdesc} handleClick={e => gotoButtonClick('plot')} btnlabel={copy.btnDiscovermore} /></Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
      </div>
    );
  }
