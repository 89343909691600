import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

    root: {
        padding:5
    },
  
  });

export default function PageDescription(props) {

    const classes = useStyles();

    const addLineBreaks = (string) => {
        if (typeof string !== 'undefined'){
            return  string.split('\n').map((text, index) => (
                <React.Fragment key={`${text}-${index}`}>
                {text}
                <br />
                </React.Fragment>
            ));

        }
    }

  return (
    <div className={classes.root}>
        <Typography variant="body1" color="inherit">
            {addLineBreaks(props.copy)}
        </Typography>
    </div>
    );
}