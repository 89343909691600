import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PageTitle from './pageTitle';
import PageDescription from './pageDescription';

import { PageContext } from "../context/page-context";

const imgService = require('../images/team.jpg');
const bkgroundImg = require('../images/main-bg-img4.jpg');

const useStyles = makeStyles({

  root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    background: `url(${bkgroundImg}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 20
  },

  grid: {
    width: 1200,
    marginTop: 40,
  },

  blockCenter: {
    textAlign: 'center',
  },
  blockLeft: {
    textAlign: 'left',
    padding: 5
  },
});

export default function BusinessPage(props) {

  const classes = useStyles();
  const [State, Dispatch] = useContext(PageContext);
  const [copy, setCopy] = useState({});

   useEffect(() => {
    switch(State.language){
      case 'en':
        setCopy({
          pageheadline:"Business Consultancy Services",
          pageheadlinedesc:"",
          pageExchange:"School Exchange",
          pageExchangedesc1:"For students and teachers, the culture exchange between schools is a very interesting experience, especially as exchange with overseas schools is more like a short-term overseas trip. You can experience foreign cultures, make foreign friends, and improve international competitiveness. \n \n The British government has a comprehensive planning guide, children protection guidelines and traveller scheme for inter-school communication. Many private schools in the United Kingdom, strictly abide by these terms, and they also have their own partner schools usually in Europe or the United States, where they have long-term cultural exchanges and academic discussions. \n \n Our company has good partnerships with many private schools. If your school is interested in doing a short-term inter-school exchanges with British schools, please contact us and we will act as a bridge to establish a connection between you and the British schools.",
          pageExchangedesc2:"Please email office@hongyangedu.uk for additional infomation",
          pageInvest:"Investing in UK Education",
          pageInvestdesc1:"In recent years, the international education sector has developed rapidly, and more and more British schools have settled and opened a branch in China. Among them include Harrow College, Dulwich College and Wellington College, currently there are around 20 schools located across China representing British institutes.  Aside from British schools opening branches in China, Chinese companies have also been busy investing and acquiring private schools here in the UK. In October 2018 Bright Scholar, the largest operator of international and bilingual schools in China, bought Bournemouth Collegiate School, a boarding school. St Bees, a co-educational independent school in Cumbria, was bought by Full Circle Education, a Hong Kong-based education group, and has reopened recently. Ipswich High School for Girls was sold to Ipswich Education Ltd, backed by the Chinese Wanda Group, whose assets total about £6 billion. \n \n If you are interested in cooperating with a British private school, to acquire or invest in a British private school, please contact us. We will provide you with industry information, school selection reports, initiate contact with British schools, communicate and coordinate, and hope to introduce the most formal British College to China. ",
          pageInvestdesc2:"Please email office@hongyangedu.uk for additional infomation.",
        });
      break;
      default:
      case 'cn':
        setCopy({
          pageheadline:"机构企业咨询",
          pageheadlinedesc:"",
          pageExchange:"校际交流",
          pageExchangedesc1:"对于学生和老师来说，学校与学校之间的文化交流是一种非常有趣的体验，尤其是与海外学校之间的交流，更像是一次短期的海外旅行，可以感受异国文化，结交外国朋友，提高国际竞争力。\n \n 英国政府对于校际交流有着完善的规划指南,儿童安全准则和旅行者计划。英国很多私立学校都严格遵守条款，也都有他们在欧洲或美国的“姐妹”学校，长期进行着文化交流和学术讨论。\n \n 我们公司与很多私立学校有着良好的合作伙伴关系，如果您的学校有兴趣与英国的学校做短期校际交流，请与我们联系，我们会起到桥梁作用，建立您的学校和英国学校之间的关联。",
          pageExchangedesc2:"请发邮件至 office@hongyangedu.uk。",
          pageInvest:"投资英国教育",
          pageInvestdesc1:"近些年，国际教育迅速发展，越来越多的英国品牌学校入驻中国，其中哈罗公学，德威公学，惠灵顿公学等英国学校已经在中国多个主要城市建立分校二十余家。与此同时，中国公司在英国收购或投资私校也成为英国私立教育发展的新动力，据不完全统计，过去四年里，包括香港在内的中资教育或投资机构收购了接近20家英国私立学校。 2018年10月，中国最大的国际双语学校运营商博实乐教育集团收购了一家寄宿学校伯恩茅斯学校； 位于坎布里亚郡的私立学校St Bees被总部位于香港的合一教育有限公司收购，并于近期重新开放； 伊普斯维奇女子中学被中国万达集团下的伊普斯维奇教育有限公司收购。\n \n 如果您有兴趣与英国私立学校联合办学或收购投资英国私校，请与我们联系，我们将为您提供行业资讯，择校报告，联系英国学校，沟通协调，希望引进最正规的英伦学院去中国，也希望您能收购投资到最好的英国私立学校。需要特别强调的是，我们仅提供教育咨询，实际投资与操作请听从法律人士建议。",
          pageInvestdesc2:"请发邮件至 office@hongyangedu.uk。",
        });
      break;
    }
  }, [State.language]);

  return (
    <div className={classes.root}>
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}><img width='100%' src={imgService} alt="" /></Grid>
      <Grid item xs={12}>
        <PageTitle headline={copy.pageheadline} />
      </Grid>

      <Grid item xs={false} sm={2} lg={2}></Grid>
      <Grid item xs={12} sm={8} lg={8}>
        <div className={classes.blockLeft}>
          <h2>{copy.pageExchange}</h2>
          <PageDescription copy={copy.pageExchangedesc1} />
          <PageDescription copy={copy.pageExchangedesc2} />
        </div> 
      </Grid>
      <Grid item xs={false} sm={2} lg={2}></Grid>

      <Grid item xs={false} sm={2} lg={2}></Grid>
      <Grid item xs={12} sm={8} lg={8}>
        <div className={classes.blockLeft}>
          <h2>{copy.pageInvest}</h2>
          <h2>{copy.pageExchange}</h2>
          <PageDescription copy={copy.pageInvestdesc1} />
          <PageDescription copy={copy.pageInvestdesc2} />
        </div>
      </Grid>
      <Grid item xs={false} sm={2} lg={2}></Grid>
    </Grid>
    </div>
  );
}
