import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  bg: {
    backgroundColor: "#01233f",
    color: "#fff",
    padding:5
  },
});


  class Footer extends Component {
  
    render() {

      const footerdesc1 = "HongYang Education Limited is not an education provider. We only provide information and facilitate the opportunities. Any offers to study is made independently by the education provider. You are responsible for the appropriate use of the material provided by this site. Your use of this site is subject to our Terms of Use.\n";
      const footerdesc2 = "\u00A9HongYang Education Limited 2020 - Registered Company Number: 12402769";
   
  
      const { classes } = this.props;

      const addLineBreaks = string =>
      string.split('\n').map((text, index) => (
        <React.Fragment key={`${text}-${index}`}>
          {text}
          <br />
        </React.Fragment>
      ));

        return (       
        <div className={classes.bg}>
        <Grid container spacing={2}>
          <Grid item sm={2} xs={false}></Grid>
            <Grid item sm={8} xs={12}>
              <Typography variant="body2" gutterBottom>
                {addLineBreaks(footerdesc1)}
                {addLineBreaks(footerdesc2)}
              </Typography>
            </Grid>
            <Grid item sm={2} xs={false}></Grid>
        </Grid>
        </div>
        )
    }
  }
  
  export default withStyles(styles)(Footer)