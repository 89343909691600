import React, {useState, useEffect, useContext} from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import PageTitle from './pageTitle';
import PageDescription from './pageDescription';

import { PageContext } from "../context/page-context";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faGlobeEurope, faPhone} from '@fortawesome/free-solid-svg-icons';
import { faQq, faWeixin } from '@fortawesome/free-brands-svg-icons';
library.add(faEnvelope, faMapMarkerAlt, faGlobeEurope, faPhone, faQq, faWeixin);

const imgContact = require('../images/team.jpg');
const bkgroundImg = require('../images/main-bg-img2.jpg');

const useStyles = makeStyles({

  root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    background: `url(${bkgroundImg}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 20
  },

  grid: {
    width: 1200,
    marginTop: 40,
  },
  firstblockCenter: {
    marginTop: 100,
    textAlign: 'center'
  },
  blockCenter: {
    textAlign: 'center',
  },
  heading: {
    textAlign: 'center', // <-- the magic
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    color: '#01233f'
  },
  textSize:{
    fontSize:14
  }
});

export default function ContactPage(props) {

  const classes = useStyles();
  const [State, Dispatch] = useContext(PageContext);
  const [copy, setCopy] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaKey, setCaptchaKey] = useState(process.env.REACT_APP_ReCAPTCHA);
  const [messageSent, setMessageSent] = useState(false);
  const [messageFailed, setMessageFailed] = useState(false);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

   useEffect(() => {
    switch(State.language){
      case 'en':
        setCopy({
          pageheadline1:"Contact Us",
          pagedesc:"For more information about the services we provide please contact us on one of the options below and one of the team will be in touch shortly.",
          contactname:"Your Name",
          contactnamevalue:"",
          contactemail:"Your Email",
          contactemailvalue:"",
          contactphone:"Your Phone Number",
          contactphonevalue:"",
          contactnumber:"Your WeChat or QQ ID",
          contactnumbervalue:"",
          contactmessage:"Your Message",
          sendvalue: "Send",
          addresslabel:"Address",
          emaillabel:"Email",
          emailaddress:"office@hongyangedu.uk",
          phonelabel:"Phone",
          phonenumber:"UK: +447366315383",
          qqlabel:"QQ",
          qqid: "2294770589",
          weixinlabel: "WeChat",
          weixinid: "hongyangedu",
          companyname: "Hongyang Education",
          addline1: "UK",
          addline2: "",
          addpostcode: "",
          addcountry: "",
        });
      break;
      default:
      case 'cn':
        setCopy({
          pageheadline1:"联系方式",
          pagedesc:"弘扬教育，我们只做我们精通的。如果您有任何问题请尽快与我们联系，我们团队会竭诚为您提供高质量的服务。",
          contactname:"Your Name",
          contactnamevalue:"",
          contactemail:"Your Email",
          contactemailvalue:"",
          contactphone:"Your Phone Number",
          contactphonevalue:"",
          contactnumber:"Your WeChat or QQ ID",
          contactnumbervalue:"",
          contactmessage:"Your Message",
          sendvalue: "Send",
          pageCompanyaddtitle:"公司地址",
          addresslabel:"地址",
          emaillabel:"邮箱",
          emailaddress:"office@hongyangedu.uk",
          phonelabel:"电话号码",
          phonenumber:"UK: +447366315383",
          qqlabel:"QQ",
          qqid: "2294770589",
          weixinlabel: "微信",
          weixinid: "hongyangedu",
          companyname: "弘扬教育咨询有限公司",
          addline1: "",
          addline2: "",
          addpostcode: "",
          addcountry: "英国",
        });
      break;
    }
  }, [State.language]);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessageSent(false);
    setMessageFailed(false);
  };

  function onCaptchaChange(value) {
    setCaptcha(value);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    const template_params = {
      "reply_to": email,
      "from_name": name,
      "to_name": "Keira",
      "from_email": email,
      "from_phonel": phone,
      "from_qq": number,
      "message_html": message
   }
   
   const service_id = process.env.REACT_APP_EMAILJS_SERVICEID;
   const template_id = process.env.REACT_APP_EMAILJS_TEMPLATEID;
   const user_id = process.env.REACT_APP_EMAILJS_USERID;

   emailjs.send(service_id, template_id, template_params,user_id)
   .then((result) => {
      setMessageSent(true);
      setMessageFailed(false);
      setName('');
      setEmail('');
      setPhone('');
      setNumber('');
      setMessage('');

    }, (error) => {
      setMessageSent(false);
      setMessageFailed(true)
        console.log(error.text);
    });
  };

  return (
    <div className={classes.root}>
    <Grid container className={classes.root} spacing={2}>

      <Grid item xs={12}><img width='100%' src={imgContact} alt="" /></Grid>
      <Grid item xs={12}>
        <PageTitle headline={copy.pageheadline1} />
        <div className={classes.blockCenter}><PageDescription copy={copy.pagedesc} /></div>
      </Grid>          
      <Grid item xs={false} sm={2} lg={3}></Grid>
      <Grid item xs={12} sm={8} lg={6}>
          <TextField required id="contactname" InputProps={{ classes: { input: classes.textSize,},}} margin="dense" size="small" label={copy.contactname} defaultValue={name} variant="outlined" onChange={e => setName(e.target.value)} fullWidth/>
          <TextField required id="contactemail" InputProps={{ classes: { input: classes.textSize,},}} margin="dense" size="small" label={copy.contactemail} defaultValue={email} variant="outlined" onChange={e => setEmail(e.target.value)} fullWidth/>
          <TextField required id="contactphone" InputProps={{ classes: { input: classes.textSize,},}} margin="dense" size="small" label={copy.contactphone} defaultValue={phone} variant="outlined" onChange={e => setPhone(e.target.value)} fullWidth/>
          <TextField id="contactnumber" InputProps={{ classes: { input: classes.textSize,},}} margin="dense" size="small" label={copy.contactnumber} defaultValue={number} variant="outlined" onChange={e => setNumber(e.target.value)} fullWidth/>
          <TextField id="contactmessage" InputProps={{ classes: { input: classes.textSize,},}} margin="dense" rows={4} multiline size="small" label={copy.contactmessage} defaultValue={message} variant="outlined" onChange={e => setMessage(e.target.value)} fullWidth/>
          <ReCAPTCHA sitekey={captchaKey} onChange={onCaptchaChange} />
          <Button variant="outlined" type="submit" className={classes.sendbutton} onClick={sendEmail}>{copy.sendvalue}</Button>
      </Grid>
      <Snackbar open={messageSent} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Message successfully sent!
        </Alert>
      </Snackbar>
      <Snackbar open={messageFailed} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Message failed to send!
        </Alert>
      </Snackbar>
      <Grid item xs={false} sm={2} lg={3}></Grid>
      <Grid item xs={false} sm={2} lg={3}></Grid>
      <Grid item xs={12} sm={3} lg={2}>
      <div className={classes.firstblockCenter}>
          <FontAwesomeIcon icon={faGlobeEurope} size="3x" color="#01233f"/>
          <Typography variant="h3" color="inherit"><div className={classes.heading}>{copy.addresslabel}</div></Typography>
          <p>{copy.companyname}<br/>
          {copy.addline1}<br/>
          {copy.addline2}<br/>
          {copy.addpostcode}<br/>
          {copy.addcountry}</p>

        </div> 
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
      <div className={classes.firstblockCenter}>
          <FontAwesomeIcon icon={faEnvelope} size="3x" color="#01233f"/>
          <Typography variant="h3" color="inherit"><div className={classes.heading}>{copy.emaillabel}</div></Typography>
            <p>{copy.emailaddress}</p>
        </div> 
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
      <div className={classes.firstblockCenter}>
          <FontAwesomeIcon icon={faPhone} size="3x" color="#01233f"/>
          <Typography variant="h3" color="inherit"><div className={classes.heading}>{copy.phonelabel}</div></Typography>
            <p>{copy.phonenumber}</p>
        </div> 
      </Grid>
      <Grid item xs={12} sm={3} lg={3}></Grid>

      <Grid item xs={false} sm={2} lg={3}></Grid>
      <Grid item xs={12} sm={3} lg={2}>
      <div className={classes.blockCenter}>
          <FontAwesomeIcon icon={faQq} size="3x" color="#01233f"/>
          <Typography variant="h3" color="inherit"><div className={classes.heading}>{copy.qqlabel}</div></Typography>
          <p>{copy.qqid}</p>
        </div> 
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
      <div className={classes.blockCenter}>
          <FontAwesomeIcon icon={faWeixin} size="3x" color="#01233f"/>
          <Typography variant="h3" color="inherit"><div className={classes.heading}>{copy.weixinlabel}</div></Typography>
            <p>{copy.weixinid}</p>
        </div> 
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>

      </Grid>
      <Grid item xs={12} sm={3} lg={3}></Grid>

    </Grid>
    </div>
  );
}
