/** 
 * Storage for session information.
 */

import React, { useReducer, createContext } from "react";

export const PageContext = createContext();

const initialState = {
    language: 'cn',
    page: 'home',
    subPage: 'home',

};


const reducer = (state, action) => {
  switch (action.type) {
    case "setLanguage":
      return { ...state, 
        language:action.payload.language,
      };
    case "setPage":
    return { ...state, 
        page: action.payload.page,
        subPage: action.payload.subPage
    };
    default:
      throw new Error();
  }
};

export const PageContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PageContext.Provider value={[state, dispatch]}>
      {props.children}
    </PageContext.Provider>
  );
};