import React, {useState, useEffect}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    menuButton: {
      color: '#273090',
        '&:hover': {
          color: '#419a1c'
        },
        marginTop: 10,
        marginBottom: 10,
    }
  });


export default function MenuButton(props) {
  const classes = useStyles();
  const [btnStyle, setBtnStyle] = useState({});

  useEffect(() => {
    if (props.selected === props.me){

      setBtnStyle({
        color: '#419a1c',
        marginTop: 10,
        marginBottom: 10,
      });
    } else {
      setBtnStyle({
        color: '#273090',
        '&:hover': {
          color: '#419a1c'
        },
        marginTop: 10,
        marginBottom: 10,
      });
    }

  }, [props.selected]);

  const handleClick = () => {
    props.handleClick();
  };

  return <Button style={btnStyle} onClick={handleClick} disabled={props.disabled}>
            <Box p={1} alignItems="center" fontWeight="fontWeightBold" fontSize={16}>{props.label}</Box>
        </Button>;
}