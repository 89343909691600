import React, { useState, useEffect, useContext } from 'react';
import { makeStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { PageContext } from "../context/page-context";

import Topbar from './Topbar';
import Footer from './Footer';

import HomeInterface from './Main';
import AboutInterface from './About';
import BusinessInterface from './Business';
import PersonalInterface from './Personal';
import ContactInterface from './Contactus';
import TeachingInterface from './Teaching';


export default function UserInterface() {

    const [state, dispatch] = useContext(PageContext);
    const [page, setPage] = useState('home');

    /**
     * Reacts on page change request.
     */
    useEffect(() => {
        setPage(state.page);
    }, [state.page]);

    /*
    { page === 'about' ? <AboutInterface /> : '' }
    { page === 'business' ? <BusinessInterface /> : '' }
    { page === 'personal' ? <PersonalInterface /> : '' }
    { page === 'teaching' ? <TeachingInterface /> : '' }
    */
    return (
        <div>
        <Grid container>
          <Grid item xs={12}>
              <Topbar />    
          </Grid>
          <Grid item xs={12}>
            { page === 'home' ? <HomeInterface /> : '' }
            { page === 'about' ? <AboutInterface /> : '' }
            { page === 'business' ? <BusinessInterface /> : '' }
            { page === 'personal' ? <PersonalInterface /> : '' }
            { page === 'contact' ? <ContactInterface /> : '' }
            { page === 'teaching' ? <TeachingInterface /> : '' }
          </Grid>
          <Grid item xs={12}><Footer /></Grid>
          
        </Grid>
        </div>
  
    );
  }