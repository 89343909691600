import React, { Component } from 'react';
import './App.css';

import UserInterface from './Components/UserInterface';
import { PageContextProvider } from "./context/page-context";

class App extends Component {
  render() {
    return (
      <div>
        <PageContextProvider>
          <UserInterface />
        </PageContextProvider>
      </div>
    );
  }
}
/*
class App extends Component {
  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </div>
    );
  }
}
*/



export default App;