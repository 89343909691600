import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    blockCenter: {
        textAlign: 'center',
    },
    staffImg:{
        '&:hover': {
          transform: 'scale(1.01,1.01)',
        },
        textAlign: 'center',
      },
  });


export default function StaffPhoto(props) {
  const classes = useStyles();

  return (
    <div className={classes.blockCenter}>
        <img className={classes.staffImg} src={props.photo} alt={props.alt} style={{width: 240, borderRadius: 240/ 2}}/>
    </div>
  )
}