import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    headline: {
        textAlign: 'center', // <-- the magic
        fontWeight: 'bold',
        fontSize: 22,
        marginTop: 20,
        marginBottom: 10,
        width: '100%',
        color: '#01233f'
      },
  });


export default function MenuButton(props) {
  const classes = useStyles();

  return (<Typography variant="h2" color="inherit">
  <div className={classes.headline}>
    {props.headline}
  </div>  
</Typography>);
}