import React, {useState, useEffect, useContext} from 'react';
import MediaQuery from 'react-responsive';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PageTitle from './pageTitle';
import PageDescription from './pageDescription';
import StaffPhoto from './aboutStaffPhoto';
import Team from './aboutStaff';

import { PageContext } from "../context/page-context";

const imgAbout = require('../images/london.jpg');
const pageTeam1photo = require('../images/Keira.jpg');
const pageTeam2photo = require('../images/Anna.jpg');
const pageTeam3photo = require('../images/Louise.jpg');
const pageTeam4photo = require('../images/Helen.jpg');
const pageTeam5photo = require('../images/Linda.jpg');
const pageTeam6photo = require('../images/Eric.jpg');

const useStyles = makeStyles({

  root: {
    flexGrow: 1,
    overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 20
  },

  grid: {
    width: 1200,
    marginTop: 40,
  },

  blockCenter: {
    textAlign: 'center',
  },
});

export default function AboutPage(props) {

  const classes = useStyles();
  const [State, Dispatch] = useContext(PageContext);
  const [copy, setCopy] = useState({});

   useEffect(() => {
    switch(State.language){
      case 'en':
        setCopy({
          pageheadline:"About Us",
          pagedesc:"Hongyang Education Limited is a British based company and we specialise in providing consultancy advice on British education institutes and providing Chinese students an opportunity to study abroad here in the UK. With over 20 years experience living and studying in the UK, we are familiar with the private and state educational system of this country. \n \n Unlike many other study abroad companies that operate, the team at Hongyang Education has direct experience with their children studying in the private school system and themselves successfully attended a British University, completed and won employment within a top local organisation. \n \n Hongyang education has a close relationship with British educational institutions and is a recruitment agent for some of these schools, we believe its not only important to choose the right school for the child, but to also ensure that the child is suitable, will thrive in the school’s environment and will proudly represent the school in the future.  \n \n  For academic institutes and businesses looking to invest in the UK private sector, we are able to offer a number of services, these services range from introducing schools together for school exchanges to provide our expect advice and utilising our relationships for investment opportunities. We also believe that in terms of providing consulting for British education.we are the most professional team.",
          pageValues:"Our Values",
          pageValuesdesc:"To provide high quality service to our customers with sincerity",
          pageTeam:"Our Team",
          pageTeam1name:"Keira Xu Msc Bsc",
          pageTeam1title:"CEO",
          pageTeam1desc:"Graduated from Cardiff University, UK. Keira has a master and bachelor's degree in science and engineering and has worked as an engineer for many years for a top UK construction / consultancy company. With twenty years of experience studying, working and living in the UK and as a mother, Keira has mastered the knowledge of the UK educational system.",
          pageTeam2name:"Dr Anna Zhang",
          pageTeam2title:"Head of Consultancy",
          pageTeam2desc:"Dr Anna Zhang graduated from Tsinghua University in China and holds a doctorate degree in civil engineering. She is a registered / chartered structural engineer in both China and the UK and has worked for a top engineering company for many years. She is an outstanding professional woman and a great mother to two exceptional children.",
          pageTeam3name:"Miki (Yan) Li Msc Bsc",
          pageTeam3title:"Senior Education Consultant",
          pageTeam3desc:"Miki graduated from the School of Economics, Wuhan University, China, and is a PhD candidate, University of Minnesota, USA.  Miki currently teaches Chinese at a leading University in England and is a dedicated teacher of Chinese.",
          pageTeam4name:"Helen Pan Msc Bsc",
          pageTeam4title:"Senior Education Consultant",
          pageTeam4desc:"Graduated from Wuhan University, China, with a master's degree in computer science. Helen has worked as a computer programmer for a well-known UK bank for many years. She is a successful professional woman and an excellent tutor. She specialises in teaching Chinese and computer programming to children, and is a children favorite.",
          pageTeam5name:"Liang Chao",
          pageTeam5title:"Director of HR, Health and Safety Department",
          pageTeam5desc:"Ms. Liang Chao is a medical staff member of the NHS in the UK. She has 20 years of rich medical experience. She will provide professional help and advice on our students' living, health and safety issues.She is a reassurance to our parents.",
          pageTeam6name:"Eric Dai Msc Bsc, ACCA",
          pageTeam6title:"CFO",
          pageTeam6desc:"Graduated from Cardiff University, UK. Eric has a master and bachelor's degree in economics and is an ACCA certified accountant. He has worked as a senior accountant in the financial industry in the City of London for many years. His passion is in economics and advising Chinese companies looking to invest in the UK.",
        });
      break;
      default:
      case 'cn':
        setCopy({
          pageheadline:"关于我们",
          pagedesc:"弘扬教育咨询公司位于英国伦敦，是为英国教育做咨询以及英国留学申请服务的专业化公司。有旅英二十年生活和学习经验，在英培养教育子女，熟知英国公立和私立教育体系和移民法律的团队为您服务。\n \n 弘扬教育，我们只做我们精通的。我们留学专家的孩子们直接就读于这些英国顶尖私立学校里，留学专家本身也都有个人留学申请成功，优秀完成学业，驰骋英国职场的案例。我们公司和英国教育机构关系紧密，是一些英国学校的在华招生代理，我们不仅为孩子选择适合的学校，同时也为英国的学校选择适合的留学生。\n \n 对于希望来英国学校做短期交流的学术机构和希望在英国教育做投资的中国公司，我们专业的团队会提供各种行业资讯，择校报告，联系英国学校，沟通协调，以及利用我们的关系提供投资机会。相信在英国教育这方面，我们是最专业的团队。",
          pageValues:"公司宗旨",
          pageValuesdesc:"我们公司的宗旨是以诚致信，以高质量的服务赢得客户。",
          pageTeam:"公司团队",
          pageTeam1name:"徐扬女士 (Keira) Msc Bsc",
          pageTeam1title:"首席执行官",
          pageTeam1desc:"毕业于英国卡迪夫大学，理工类本科硕士。曾在英国顶尖建筑工程公司和地方政府担任工程师多年。徐扬女士有着二十年的旅英学习，工作，生活以及成为妈妈后帮助孩子学习，择校的亲身体验。",
          pageTeam2name:"张婀娜博士 (Anna)",
          pageTeam2title:"首席顾问",
          pageTeam2desc:"张婀娜女士毕业于中国清华大学，拥有土木工程博士学位。中国一级注册结构工程师，英国注册结构工程师。张博士在英国最顶尖的建筑工程公司担任结构工程师多年，是一位杰出的职业女性和出色的妈妈, 她有两个同样优秀的孩子。",
          pageTeam3name:"李艳老师 (Miki) Msc Bsc",
          pageTeam3title:"资深教育顾问",
          pageTeam3desc:"李艳老师，资深中文老师，教育专家。李艳老师毕业于中国武汉大学经济学院，美国明尼苏达大学PhD candidate。李老师在英国一所著名大学教授中文多年，同时也是一位资深的GCSE主讲老师。",
          pageTeam4name:"潘玉娥老师 (Helen) Msc Bsc",
          pageTeam4title:"资深教育顾问",
          pageTeam4desc:"潘玉娥老师，毕业于中国武汉大学，计算机专业本科硕士。潘老师在英国一家知名银行担任计算机程序员多年，是一位成功的职业女性和优秀的课外辅导老师，在英国教很多小朋友中文和计算机编程，深受孩子们喜爱。",
          pageTeam5name:"梁超女士",
          pageTeam5title:"人事、健康与安全部总监",
          pageTeam5desc:"梁超女士是英国卫生局NHS的医务人员，有着二十年丰富的从医经验，会对我们学生们的生活、健康与安全问题提供专业的帮助与建议，是家长的放心剂。",
          pageTeam6name:"戴崇智 (Eric) Msc Bsc, ACCA",
          pageTeam6title:"首席财务官",
          pageTeam6desc:"毕业于英国卡迪夫大学，经济类本科硕士。国际注册会计师，金融分析师。戴崇智先生在英国伦敦知名银行担任高级会计师多年，他对金融经济学充满热情，可以对中国投资者提供专业建议。",
        });
      break;
    }
  }, [State.language]);

  return (
      <div className={classes.root}>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}><img width='100%' src={imgAbout} alt="" /></Grid>
        <Grid item xs={12}>
          <PageTitle headline={copy.pageheadline} />
        </Grid>
        <Grid item xs={false} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <PageDescription copy={copy.pagedesc} />
        </Grid>
        <Grid item xs={false} sm={2}></Grid>
        <Grid item xs={false} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <PageTitle headline={copy.pageValues} />
        </Grid>
        <Grid item xs={false} sm={2}></Grid>
        <Grid item xs={false} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <div className={classes.blockCenter}>
            <PageDescription copy={copy.pageValuesdesc} />
          </div>
        </Grid>
        <Grid item xs={false} sm={2}></Grid>
        <Grid item xs={false} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <PageTitle headline={copy.pageTeam} />
        </Grid>
        <Grid item xs={false} sm={2}></Grid>

        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <StaffPhoto photo={pageTeam1photo} alt={''} />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <Team name={copy.pageTeam1name} title={copy.pageTeam1title} description={copy.pageTeam1desc} />
        </Grid>
        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>
        
        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>
        <MediaQuery maxWidth={767}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <StaffPhoto photo={pageTeam2photo} alt={''} />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Team name={copy.pageTeam2name} title={copy.pageTeam2title} description={copy.pageTeam2desc} />
          </Grid>
        </MediaQuery>
        <MediaQuery minWidth={767}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Team name={copy.pageTeam2name} title={copy.pageTeam2title} description={copy.pageTeam2desc} />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <StaffPhoto photo={pageTeam2photo} alt={''} />
          </Grid>
        </MediaQuery>
        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>

        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <StaffPhoto photo={pageTeam3photo} alt={''} />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <Team name={copy.pageTeam3name} title={copy.pageTeam3title} description={copy.pageTeam3desc} />
        </Grid>
        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>

        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>
        <MediaQuery maxWidth={767}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <StaffPhoto photo={pageTeam4photo} alt={''} />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Team name={copy.pageTeam4name} title={copy.pageTeam4title} description={copy.pageTeam4desc} />
          </Grid>
        </MediaQuery>
        <MediaQuery minWidth={767}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Team name={copy.pageTeam4name} title={copy.pageTeam4title} description={copy.pageTeam4desc} />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <StaffPhoto photo={pageTeam4photo} alt={''} />
          </Grid>
        </MediaQuery>
        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>

        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <StaffPhoto photo={pageTeam5photo} alt={''} />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <Team name={copy.pageTeam5name} title={copy.pageTeam5title} description={copy.pageTeam5desc} /> 
        </Grid>
        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>

        
        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>
        <MediaQuery maxWidth={767}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <StaffPhoto photo={pageTeam6photo} alt={''} />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Team name={copy.pageTeam6name} title={copy.pageTeam6title} description={copy.pageTeam6desc} />
          </Grid>
        </MediaQuery>
        <MediaQuery minWidth={767}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Team name={copy.pageTeam6name} title={copy.pageTeam6title} description={copy.pageTeam6desc} />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <StaffPhoto photo={pageTeam6photo} alt={''} />
          </Grid>
        </MediaQuery>
        <Grid item xs={false} sm={1} md={2} lg={3}></Grid>
      </Grid>
      </div>
  );

}

