
import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Carousel from 're-carousel';

const img1 = require('../images/main-carousel-img1.jpg');
const img1alt = "Cambridge University ";
const img2 = require('../images/main-carousel-img2.jpg');
const img2alt = "London bus in front of UK parliament";
const img3 = require('../images/main-carousel-img3.jpg');
const img3alt = "Students walking to school";
const img4 = require('../images/main-carousel-img4.jpg');
const img4alt = "Eton College building";
const img5 = require('../images/main-carousel-img5.jpg');
const img5alt = "Students working in front of computer";

//<div style={{backgroundColor: 'tomato', height: '400px'}}>Frame 1<img width="100%" height="100%" src={img1} alt="" /></div>


const styles = theme => ({

  imgStyle: {
    width: "100%",
    /*[theme.breakpoints.down('sm')]: {
      width: "300%",
    }*/
  },

  carouselHeight: {
    height: parseInt(window.innerWidth / 1.91387) + 'px',
    /*[theme.breakpoints.down('sm')]: {
      height: parseInt(window.innerWidth * 1.5) + 'px'
    }*/
  }


});
//const carouselHeight = {
//  height: parseInt(window.innerWidth / 1.91387) + 'px',
//}
//parseInt(window.innerWidth / 1.91387) + 'px';
//console.log('carousel height:'+carouselHeight);
//className="carouselImage" 
class MainCarousel extends Component {
  render() {
/*
    const head1 = "Head Text goes here 1";
    const head2 = "Head Text goes here 2";
    const head3 = "Head Text goes here 3";
    const head4 = "Head Text goes here 4";
    const head5 = "Head Text goes here 5";
 

    <div><img width='100%' src={img1} alt="" /><div style={divStyle}><h1>{head1}</h1></div></div>
    <div><img width='100%' src={img2} alt="" /><div style={divStyle}><h1>{head2}</h1></div></div>
    <div><img width='100%' src={img3} alt="" /><div style={divStyle}><h1>{head3}</h1></div></div>
    <div><img width='100%' src={img4} alt="" /><div style={divStyle}><h1>{head4}</h1></div></div>
    <div><img width='100%' src={img5} alt="" /><div style={divStyle}><h1>{head5}</h1></div></div>

    <div><img width='100%' src={img1} alt="" /></div>
    <div><img width='100%' src={img2} alt="" /></div>
    <div><img width='100%' src={img3} alt="" /></div>
    <div><img width='100%' src={img4} alt="" /></div>
    <div><img width='100%' src={img5} alt="" /></div>

    <div style={carouselHeight} >
*/
    const { classes } = this.props;
    return (


        <div className={classes.carouselHeight} >
          <Carousel loop auto>
            <div><img className={classes.imgStyle} src={img1} alt={img1alt} /></div>
            <div><img className={classes.imgStyle} src={img2} alt={img2alt} /></div>
            <div><img className={classes.imgStyle} src={img3} alt={img3alt} /></div>
            <div><img className={classes.imgStyle} src={img4} alt={img4alt} /></div>
            <div><img className={classes.imgStyle} src={img5} alt={img5alt} /></div>
          </Carousel>
        </div>
    )
  }
}
//export default MainCarousel;
export default withStyles(styles)(MainCarousel)