import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PageTitle from './pageTitle';
import PageDescription from './pageDescription';

import { PageContext } from "../context/page-context";

const imgService = require('../images/team.jpg');
const bkgroundImg = require('../images/main-bg-img4.jpg');

const useStyles = makeStyles({

  root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    background: `url(${bkgroundImg}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 20
  },
  grid: {
    width: 1200,
    marginTop: 40,
  },
  blockCenter: {
    textAlign: 'center',
  },
  blockLeft: {
    textAlign: 'left',
    padding: 5
  },

});

export default function PersonalPage(props) {

  const classes = useStyles();
  const [State, Dispatch] = useContext(PageContext);
  const [copy, setCopy] = useState({});

   useEffect(() => {
    switch(State.language){
      case 'en':
        setCopy({
          pageheadline:"Online Education",
          pageheadlinedesc:"Hosted by elite tutors who are doctoral’s within their chosen profession and in conjunction with Hongyang Education, we are pleased to be able to offer a number of online teaching course. So that wherever you are, you can benefit from lessons with Hongyang Education in the cloud. Courses currently available include: \n \n Mathematics, Mathematical reasoning \n Chinese, English \n Computer Programming \n\n Prices start from £5 per lesson, with class sizes ranging from 3 to 5 students. If you have any questions, please contact us and we will contact you as soon as possible.",
          pageMaths:"Mathematics and Mathematical reasoning Department",
          pageMathsdesc:"The mathematics and mathematical reasoning teaching curriculum taught by Hongyang education is based on the British elementary school syllabus created by senior British education experts. It is especially suited for British and Chinese students who wish to prepare for the 7+, 8+ or 11+ exams. The tutor of these lessons, not only has rich experience in preparing for the test, but also outstanding success in auxiliary studies. We have helped students successfully gain entry to St. Paul’s School, the King’s School and Local Grammar School. \n \n We hope to help students in the following areas: \n Develop a positive learning attitude \n Stimulate learning initiative and interest in mathematics \n Produce a feasible learning plan \n Supervise students to finish their homework \n Exercise logical thinking ability \n Improve math performance \n Pre-exam written/interview counseling",
          pageMathsteacher: "Teacher in charge: Dr Anna Zhang. Dr. Zhang graduated from Tsinghua University in China with a doctorate degree in civil engineering and is a registered structural engineer in the United Kingdom and China. Dr Zhang has lived in the UK for 13 years, is a successful professional woman and an excellent and compassionate teacher. Dr Zhang specialises in teaching and tutoring students who would like to gain entrance into UK’s top private or grammar schools in maths and reasoning.  Dr Zhang has successfully guided her own two children to pass the 7+ and 11+ exams as well as tutored many other students to the same level of success.",
          pageChineseEnglish:"Chinese and English department ",
          pageChinese:"Chinese",
          pageChinesedesc:"Hongyong Education has launched a series of online 'Learn Chinese with Auntie Louise' courses during the epidemic in the UK. 'Learn Chinese with Auntie Louise' adopts the same teaching methodology as utilised by local Chinese schools, has received lots of positive feedback and takes advantage of the online meeting tool Zoom for delivery. The course provides an opportunity for Chinese and English children who would love to learn Chinese to study in an interesting and small group environment. \n \n This course is suitable for: \n From beginners to GCSE level children aged from 5 to 16 \n Text books \n The course will be based on the internationally recognised text book’s 'Chinese' from Jinan University. \n Method to teach \n 30 minutes one-to-one online teaching \n 40 minutes one to four online teaching.",
          pageEnglish:"English",
          pageEnglishdesc:"The English courses by Hongyang education are designed for the native Chinese speakers who would like to learn and improve their English. Miki (Yan) Li will teach English vocabulary, spelling, grammar, reading, and writing. Plus arrange oral English lessons with a native English speakers every couple of weeks or so. \n This course is suitable for children aged between 5 to 16 years \n Text books: Cambridge English for young learners and Oxford reading tree \n Method to teach: 30 minutes one-to-one or 40mins one to four online teaching  ",
          pageChineseEnglishteacher:"Teacher in charge: Miki (Yan) Li, Senior Chinese teacher and education expert. Louise graduated from the School of Economics, Wuhan University, China, and is a PhD candidate, University of Minnesota, USA.  Louise currently teaches Chinese at a leading University in England and is a dedicated teacher of Chinese.",
          pageProgamming: "Computer Programming Department",
          pageProgammingdesc: "Build your own story with code, build your own imagination with modules, a must-have in the era of artificial intelligence and computer programming. Hongyang education, in order to meet the strong demands of parents and students has created its own popular online children's programming course. \n \n This course is suitable for children of school age who understands basic maths \n Programming languages taught include Block-based coding, Python, and Scratch \n Method to teach One to three, forty minutes, online teaching.",
          pageProgammingteacher: "Teacher in charge: Dr Warren Liu graduated from the Department of Computer Science, University of Essex, United Kingdom. He has a PhD in computer science and has been engaged in programming for a well-known British bank for many years. Dr. Liu was born in a family of educators, has his own deep insights into programming, and is also very knowledgeable in teaching children. He is an experienced professional and a good teacher who loves to teach."
        });
      break;
      default:
      case 'cn':
        setCopy({
            pageheadline:"线上教育",
            pageheadlinedesc:"弘扬教育的博士精英导师团隆重推出线上教学课程，让您无伦身在何方，都可以和弘扬相约在云课堂。课程包括： \n \n 数学，逻辑 \n 中文，英文 \n 计算机编程 \n\n 小组课价格从5镑一节课起，科目及人数不同，收费也有所不同，任何疑问请在留言版留言，我们会尽快与您联系。",
            pageMaths:"数学，逻辑教学部门",
            pageMathsdesc:"弘扬教育的数学逻辑教学课程是根据英国小学的教学大纲，由英国资深教育专家编排，尤其适合于英国本土和中国留学生备战7+，8+和11+考试。我们强大的导师团，不仅有丰富的备考授课经验，更有出色的辅学成功成果，曾经帮助学生们成功进入圣保罗公学，国王学校和英国当地重点中学（grammar school)。 \n \n 我们希望在以下方面帮助学生： \n 培养积极向上的学习态度 \n 激发学习主动性和对数学的兴趣 \n 制定可行学习计划 \n 督促学生付之行动 \n 锻炼逻辑思维能力 \n 提高数学成绩 \n 考前笔试/面试辅导",
            pageMathsteacher: "负责老师：张婀娜老师，资深育儿专家。张老师毕业于中国清华大学，拥有土木工程博士学位，中国，英国注册结构工程师。张老师是一位成功的职业女性和优秀的课外辅导老师。张老师在英国生活13年，两个孩子分别通过7+和11+考试，在辅助自己孩子成长的同时，也辅导很多其他孩子，学习都有显著进步，是一位非常有经验的7+8+和11+数学，逻辑老师。",
            pageChineseEnglish:"中文，英文教学部门",
            pageChinese:"中文",
            pageChinesedesc:"弘扬教育的中文教学采用当下最流行的Zoom 线上教学，沿用当地中文学校一整套的专业教学体系，兴趣教学，个性教育，给华裔少年儿童以及喜爱中文的英国小朋友一个学习中文，亲近中文的机会。 \n \n 适合人群 \n 从零基础到GCSE水平，5到16岁少儿。 \n 课程大纲 \n 海外中文学校应用最广泛，影响力最大的暨南大学的《中文》教材。 \n 教学形式 \n一对一，半小时线上教学；一对四，四十分钟线上教学",
            pageEnglish:"英文",
            pageEnglishdesc:"弘扬教育的英语课程主要针对国内英语爱好者，由李老师主讲英语词汇，拼写，语法，阅读，写作，根据需求每周或每两周安排一次英国本土外教口语课。\n \n 适合人群 \n 5到16岁国内少儿英语爱好者 \n 教学大纲 \n 英国剑桥少儿英语和牛津阅读树 \n 教学形式 \n 一对一，半小时线上课程；一对四，四十分钟线上教学",
            pageChineseEnglishteacher:"主讲老师：李燕老师，资深中文老师，教育专家。李艳老师毕业于中国武汉大学经济学院，美国明尼苏达大学PhD candidate。李老师在英国一所著名大学教授中文多年，同时也是一位资深的GCSE主讲老师",
            pageProgamming: "计算机编程部门",
            pageProgammingdesc: "用代码构建你的故事，用模块搭建你的想象，人工智能时代的必须课，计算机编程。弘扬教育为了满足广大学生家长的强烈要求，推出了热门火爆的线上少儿编程课程。 \n \n 适合人群 \n 会基本数学的学龄少年儿童 \n 编程语言 \n Block-based coding, Python, Scratch \n 教学形式 \n 一对三，四十分钟线上教学",
            pageProgammingteacher: "负责老师：刘武东老师，刘老师毕业于英国埃塞克斯大学计算机系，计算机博士，在英国一家知名银行从事程序设计多年。刘老师出生于教育世家，对于编程有自己深刻的见解，对于少儿教学也潜心研究，颇具心得，是一位有经验的专业人士和热爱教育事业的好老师。"
        });
      break;
    }
  }, [State.language]);

  return (
    <div className={classes.root}>
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}><img width='100%' src={imgService} alt="" /></Grid>
      <Grid item xs={false} sm={2} lg={2}></Grid>
      <Grid item xs={12} sm={8} lg={8}>
        <PageTitle headline={copy.pageheadline} />
        <div className={classes.blockLeft}>
            <PageDescription copy={copy.pageheadlinedesc} />
        </div>
      </Grid>
      <Grid item xs={false} sm={2} lg={2}></Grid>

      <Grid item xs={false} sm={2} lg={2}></Grid>
      <Grid item xs={12} sm={8} lg={8}>
        <div className={classes.blockLeft}>
          <h2>{copy.pageMaths}</h2>
          <PageDescription copy={copy.pageMathsdesc} /><br />
          <PageDescription copy={copy.pageMathsteacher} />
        </div>
      </Grid>
      <Grid item xs={false} sm={2} lg={2}></Grid>

      <Grid item xs={false} sm={2} lg={2}></Grid>
      <Grid item xs={12} sm={8} lg={8}>
        <div className={classes.blockLeft}>
          <h2>{copy.pageChineseEnglish}</h2>
          <h2>{copy.pageChinese}</h2>
          <PageDescription copy={copy.pageChinesedesc} />
          <h2>{copy.pageEnglish}</h2>
          <PageDescription copy={copy.pageEnglishdesc} /><br />
          <PageDescription copy={copy.pageChineseEnglishteacher} />
        </div>
      </Grid>
      <Grid item xs={false} sm={2} lg={2}></Grid>

      <Grid item xs={false} sm={2} lg={2}></Grid>
      <Grid item xs={12} sm={8} lg={8}>
        <div className={classes.blockLeft}>
          <h2>{copy.pageProgamming}</h2>
          <PageDescription copy={copy.pageProgammingdesc} /><br />
          <PageDescription copy={copy.pageProgammingteacher} />
        </div>
      </Grid>
      <Grid item xs={false} sm={2} lg={2}></Grid>
    </Grid>
    </div>
  );
}
