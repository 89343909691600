import React, {useState, useEffect, useContext} from 'react';
import MediaQuery from 'react-responsive'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuButton from './TopMenuButton';

import Select from '@material-ui/core/Select';

import { PageContext } from "../context/page-context";

const logo = require('../images/logo.png');

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    background: 'white',
    color: 'white',
  },
  logo: {
    maxWidth: 160,
  },
  toolbar: {
    background: 'white',
    minHeight: 80,
    alignItems: 'flex-start',
  },
  menuoptions: {
    display:"flex",
    flexDirection:"row",
    justifyContent: "space-around",
    paddingTop: 30
  },
  burgermenuoptions: {
    display:"flex",
    flexDirection:"row",
    justifyContent: "space-around",
    paddingTop: 45,
  },
  languageoptions: {
    flexGrow: 1,
    display:"flex",
    justifyContent: "flex-end",
    paddingTop: 45,
  },
  
  
});

export default function ProminentAppBar(props) {

  const classes = useStyles();
  const [State, Dispatch] = useContext(PageContext);
  const [view, setView] = useState('home');
  const [language, setLanguage] = useState('en');
  const [copy, setCopy] = useState({});


  useEffect(() => {

    Dispatch({
      type: "setLanguage",
      payload: { language: language}
    });

  }, [language]);


  useEffect(() => {

    Dispatch({
      type: "setPage",
      payload: { page: view, subPage: view}
    });

  }, [view]);


   useEffect(() => {
    switch(State.language){
      case 'en':
        setCopy({
          displayLanguage:"English",
          home:"Home",
          about:"About Us",
          personal:"Personal Application",
          business:"Business Consultancy",
          learning:"Online education",
          contact:"Contact Us",
          burgermenu: "Menu"
        });
      break;
      default:
      case 'cn':
        setCopy({
          displayLanguage:"中文",
          home:"首页",
          about:"团队介绍",
          personal:"个人留学申请",
          business:"机构企业咨询",
          learning:"线上教育",
          contact:"联系方式",
          burgermenu: "菜单"
        });
      break;
    }
  }, [State.language]);


  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Box p={1} flexGrow={1}> <img src={logo} alt="" className={classes.logo} /></Box>
          <MediaQuery maxWidth={767}>
          <div className={classes.burgermenuoptions}>
            <Box alignItems="center" justifyContent="center">
                  <Select
                    labelId=""
                    id=""
                    disableUnderline={true}
                    value={view}
                    onChange={e => setView(e.target.value)}
                  >
                  <MenuItem value={'home'}>{copy.home}</MenuItem>
                  <MenuItem value={'about'}>{copy.about}</MenuItem>
                  <MenuItem value={'personal'}>{copy.personal}</MenuItem>
                  <MenuItem value={'business'}>{copy.business}</MenuItem>
                  <MenuItem value={'teaching'}>{copy.learning}</MenuItem>
                  <MenuItem value={'contact'}>{copy.contact}</MenuItem>
                </Select>
              </Box>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={767}>
          <div className={classes.menuoptions}>
            <Box alignItems="center" justifyContent="center"><MenuButton label={copy.home} me={'home'} selected={view} disabled={false} handleClick={e => setView('home')} /></Box>
            <Box alignItems="center" justifyContent="center"><MenuButton label={copy.about} me={'about'} selected={view} disabled={false} handleClick={e => setView('about')} /></Box>
            <Box alignItems="center" justifyContent="center"><MenuButton label={copy.personal} me={'personal'} selected={view} disabled={false} handleClick={e => setView('personal')} /></Box>
            <Box alignItems="center" justifyContent="center"><MenuButton label={copy.business} me={'business'} selected={view} disabled={false} handleClick={e => setView('business')} /></Box>
            <Box alignItems="center" justifyContent="center"><MenuButton label={copy.learning} me={'teaching'} selected={view} disabled={false} handleClick={e => setView('teaching')} /></Box>
            <Box alignItems="center" justifyContent="center"><MenuButton label={copy.contact} me={'contact'} selected={view} disabled={false} handleClick={e => setView('contact')} /></Box>
          </div>
          </MediaQuery>
          <div className={classes.languageoptions}>
            <Box alignItems="center" justifyContent="center">
                <Select
                  labelId=""
                  id=""
                  disableUnderline={true}
                  value={language}
                  onChange={e => setLanguage(e.target.value)}
                >
                <MenuItem value={'en'}>English</MenuItem>
                <MenuItem value={'cn'}>中文</MenuItem>
              </Select>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}