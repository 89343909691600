import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    blockCenter: {
        textAlign: 'center',
    },
    staffName: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 18,
        marginTop: 10,
        width: '100%',
        color: '#01233f'
      },
      stafftitle: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 20,
        width: '100%',
        color: '#01233f'
      },
      block: {
        padding: 5
      }
  });


export default function Staff(props) {
  
  const classes = useStyles();

  const addLineBreaks = (string) => {
    if (typeof string !== 'undefined'){
        return string.split('\n').map((text, index) => (
            <React.Fragment key={`${text}-${index}`}>
            {text}
            <br />
            </React.Fragment>
        ));

    }
}


  return (
    <div className={classes.block}>
        <div className={classes.staffName}>{props.name}</div>
        <div className={classes.stafftitle}>{props.title}</div>
        <p>{addLineBreaks(props.description)}</p>
    </div> 
  )
}